// const actions = {
//     multiplication: {
//         value: '*',
//         label: 'multiplication',
//         func: (a, b) => (parseInt(a) * parseInt(b))
//     },
//     division: {
//         value: '/',
//         label: 'division',
//         func: (a, b) => (a / b)
//     },
//     addition: {
//         value: '+',
//         label: 'addintion',
//         func: (a, b) => (parseInt(a) + parseInt(b))
//     },
//     subtraction: {
//         value: '-',
//         label: 'subtraction',
//         func: (a, b) => (parseInt(a) - parseInt(b))
//     }
// }

export default {
    data() {
        return {
            actions: {
                multiplication: {
                    value: '*',
                    label: 'multiplication',
                    func: (a, b) => (parseInt(a) * parseInt(b))
                },
                division: {
                    value: '/',
                    label: 'division',
                    func: (a, b) => (a / b)
                },
                addition: {
                    value: '+',
                    label: 'addintion',
                    func: (a, b) => (parseInt(a) + parseInt(b))
                },
                subtraction: {
                    value: '-',
                    label: 'subtraction',
                    func: (a, b) => (parseInt(a) - parseInt(b))
                }
            }
        }
    },
    methods: {
        parseBrackets(str) {
            const out = str.match(/\((.*)\)/);
            if (out) {
                const expResult = this.parseBrackets(out[1]);
                str = str.replace(out[0], expResult);
                return this.calcExpr(str);
            } else {
                return this.calcExpr(str);
            }
        },
        calcExpr(str) {
            let res;
            Object.keys(this.actions).map((type) => {
                res = this.parseExpr(str, this.actions[type]);
                if (res) {
                    str = str.replace(res.str, res.value.toString());
                    str = this.calcExpr(str);
                }
            });
            return str;
        },
        parseExpr(str, action) {
            const reg = new RegExp(`((\\d+)\\s*\\${action.value}\\s*(\\d+))`);
            const out = str.match(reg);
            if (!out) return false;

            const result = {
                str: out[1]
            };

            result.value = action.func(out[2], out[3]);
            return result;
        }
    }
}