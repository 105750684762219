<template>
  <div class="nav-container mt-3 mb-3" id="nav-container">
    <div class="nav-bar left">
      <div class="row g-0">
        <div class="col-auto">
          <div class="nav-item active">Заявки</div>
        </div>
        <div class="col-auto">
          <div class="nav-item">Клиенты</div>
        </div>
        <div class="col-auto">
          <div class="nav-item">Проекты</div>
        </div>
      </div>
    </div>
    <div class="nav-bar right">
      <div class="boxed">Общее количество строк: {{ countRows }}</div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: "NavBar",
  components: {},
  props: {
    countRows: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss">
.nav-container {
  margin: 0 50px;
  display: flex;
  justify-content: space-between;
}

.nav-item {
  padding: 6px 15px;
  text-transform: uppercase;
  font-size: 14px;
  cursor: pointer;
  margin-right: 5px;

  &.active,
  &:hover {
    background: #fff;
    color: #568300;
    border-radius: 5px;
    box-shadow: 0px 2px 2px rgba(52, 79, 0, 0.25);
  }

  &.active {
    font-weight: 700;
  }
}

.boxed {
  color: #568300;
  padding: 6px 15px;
  border-radius: 5px;
  background: #fff;
  font-weight: 700;
}
</style>
