<template>
  <div class="container-fluid g-0 d-flex flex-column min-vh-100">
    <NavBar :countRows="tableData.length" />
    <div class="row g-0 flex-grow-1">
      <div class="col-12">
        <div class="resizer-wrap h-100" id="resizer-wrap">
          <div class="table-wrap" id="table-wrap">
            <DataTable :headers="headers" :tableData="tableData" />
            <!-- <div
              class="resizer-bottom"
              id="resizer-bottom"
              @mousedown="resizerVertical"
            ></div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="footer" id="bottom">
      <div
        class="resizer-bottom"
        id="resizer-bottom"
        @mousedown="resizerVertical"
      ></div>
      <div class="row g-0 pane-top-container">
        <div class="col-12">
          <div class="view-container">
            <div class="view-box"></div>
          </div>
        </div>
      </div>
      <div class="row g-0">
        <div class="col">
          <div class="pane-wrapper" id="pane-wrapper">
            <div class="pane left" id="left">
              <div class="pane-inner">
                <div
                  class=""
                  @click="editColumn"
                  @keypress="sendDataOfColumn"
                  @blur="sendDataOfColumn"
                  @keydown.esc="sendDataOfColumnEsc"
                >
                  Москва, ЦАО, р-н Басманный, Подсосенский пер., 3к1<br />
                  м. Курская - 9 мин, м. Чкаловская - 11 мин, м. Чистые пруды -
                  14 мин<br />
                  Общая площадь: 73,2 м2<br />
                  Этаж: 3 из 4<br />
                  Год постройки: 2009<br />
                  Цена за метр: 940 984 р/м2<br />
                  Продажа от собственника<br />
                  Торга нет
                </div>
              </div>
            </div>
            <div class="pane right" id="right">
              <div class="gutter" @mousedown="resizer" id="#gutter"></div>
              <div class="pane-inner">
                <div
                  class=""
                  @click="editColumn"
                  @keypress="sendDataOfColumn"
                  @blur="sendDataOfColumn"
                  @keydown.esc="sendDataOfColumnEsc"
                >
                  05.06.2023 - запланировали встречу по сделке<br />
                  04.06.2023 - клиент сообщил, что предложенный вариант
                  интересен, готовы смотреть<br />
                  01.06.2023 - поступил звонок от потенциального клиента,
                  интересующегося квиратрой. Рассказал своих пожеланиях,
                  связанных с расположением, планировкой и состоянием
                  квартиры.<br />
                  05.06.2023 - запланировали встречу по сделке<br />
                  04.06.2023 - клиент сообщил, что предложенный вариант
                  интересен, готовы смотреть<br />
                  01.06.2023 - поступил звонок от потенциального клиента,
                  интересующегося квиратрой. Рассказал своих пожеланиях,
                  связанных с расположением, планировкой и состоянием
                  квартиры.<br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="row g-0">
      <div class="col-12">
        <div class="view-container long">
          <div class="resizer"></div>
          <div class="row g-0">
            <div class="col-6">
              <div class="view-container">
                <div
                  class="view-box"
                  @click="editColumn"
                  @keypress="sendDataOfColumn"
                  @blur="sendDataOfColumn"
                  @keydown.esc="sendDataOfColumnEsc"
                >
                  Москва, ЦАО, р-н Басманный, Подсосенский пер., 3к1<br />
                  м. Курская - 9 мин, м. Чкаловская - 11 мин, м. Чистые пруды -
                  14 мин<br />
                  Общая площадь: 73,2 м2<br />
                  Этаж: 3 из 4<br />
                  Год постройки: 2009<br />
                  Цена за метр: 940 984 р/м2<br />
                  Продажа от собственника<br />
                  Торга нет
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="view-container">
                <div
                  class="view-box view-box-right"
                  @click="editColumn"
                  @keypress="sendDataOfColumn"
                  @blur="sendDataOfColumn"
                  @keydown.esc="sendDataOfColumnEsc"
                >
                  05.06.2023 - запланировали встречу по сделке<br />
                  04.06.2023 - клиент сообщил, что предложенный вариант
                  интересен, готовы смотреть<br />
                  01.06.2023 - поступил звонок от потенциального клиента,
                  интересующегося квиратрой. Рассказал своих пожеланиях,
                  связанных с расположением, планировкой и состоянием
                  квартиры.<br />
                  05.06.2023 - запланировали встречу по сделке<br />
                  04.06.2023 - клиент сообщил, что предложенный вариант
                  интересен, готовы смотреть<br />
                  01.06.2023 - поступил звонок от потенциального клиента,
                  интересующегося квиратрой. Рассказал своих пожеланиях,
                  связанных с расположением, планировкой и состоянием
                  квартиры.<br />
                </div>
              </div>
              <div class="resizer-right"></div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
/* eslint-disable */
import DataTable from "./components/DataTable";
import NavBar from "./components/NavBar";

export default {
  name: "App",
  components: {
    DataTable,
    NavBar,
  },
  data() {
    return {
      leftWidth: "50%",
      selected: [{ text: "Продажа" }, { text: "Продажа" }, { text: "Продажа" }],
      options: [
        { text: "Один", value: "Продажа" },
        { text: "Два", value: "B" },
        { text: "Три", value: "C" },
      ],
      headers: [
        {
          slug: "created",
          text: "Создана",
        },
        {
          slug: "i1",
          text: "ИП",
        },
        {
          slug: "i2",
          text: "И2",
        },
        {
          slug: "client",
          text: "Клиент (услуги)",
          type: "editable-text",
        },
        {
          slug: "service",
          text: "Услуга",
          type: "select",
          options: [
            { text: "Альтернатива", value: "Альтернатива" },
            { text: "Продажа", value: "Продажа" },
            { text: "Юр. сопровождение", value: "Юр. сопровождение" },
          ],
          style: { "max-width": "initial" },
        },
        {
          slug: "object",
          text: "Объект",
        },
        {
          slug: "market",
          text: "Рынок",
        },
        {
          slug: "segment",
          text: "Сегмент",
        },
        {
          slug: "address",
          text: "Адрес",
        },
        {
          slug: "agent",
          text: "Агент",
        },
        {
          slug: "class",
          text: "Класс",
          center: true,
        },
        {
          slug: "max_budget",
          text: "Макс. бюджет",
          type: "editable-text",
          link: true,
        },
        {
          slug: "percent",
          text: "%",
          type: "editable-text",
          link: true,
        },
        {
          slug: "agency_commission",
          text: "Комиссия агентства",
          type: "formula",
          formula: "max_budget * percent / 100",
          link: true,
        },
        {
          slug: "meeting",
          text: "Встреча",
          type: "checkbox",
          center: true,
        },
        {
          slug: "next_contact",
          text: "Следующий контакт",
        },
      ],
      tableData: [
        {
          id: 1,
          created: "01.01.2023",
          i1: "онлайн",
          i2: "Директ.Поиск",
          client: "Иванов И.И.",
          service: "Продажа",
          object: "Квартира",
          market: "Вторинчый",
          segment: "Бизнес",
          address: "ул. Тверская, д. 10",
          agent: "Иванов И.И.",
          class: "A",
          max_budget: 2500000,
          percent: 5,
          agency_commission: 125000,
          meeting: false,
          next_contact: "02.02.2023",
          focus: true,
        },
        {
          id: 2,
          created: "17.01.2023",
          i1: "офлайн",
          i2: "Посещение офиса",
          client: "Смирнов А.П.",
          service: "Альтернатива",
          object: "Дом",
          market: "Новостройка",
          segment: "Элитный",
          address: "ул. Арбат, д. 50",
          agent: "Петров П.П.",
          class: "А",
          max_budget: 3700000,
          percent: 15,
          agency_commission: 555000,
          meeting: false,
          next_contact: "08.02.2023",
          focus: false,
        },
        {
          id: 3,
          created: "23.01.2023",
          i1: "офлайн",
          i2: "Звонок",
          client: "Кузнецов В.Д.",
          service: "Юр. сопровождение",
          object: "Участок",
          market: "Вторинчый",
          segment: "Эконом",
          address: "ул. Красная Пресня, д. 5",
          agent: "Иванов И.И.",
          class: "Б",
          max_budget: 2900000,
          percent: 20,
          agency_commission: 580000,
          meeting: false,
          next_contact: "07.02.2023",
          focus: false,
        },
        {
          id: 4,
          created: "27.01.2023",
          i1: "онлайн",
          i2: "Директ.РСЯ",
          client: "Попов Н.А.",
          service: "Покупка",
          object: "Таунхаус",
          market: "Новостройка",
          segment: "Эконом",
          address: "ул. Ленинский проспект, д. 20",
          agent: "Молочков И.А.",
          class: "В",
          max_budget: 1600000,
          percent: 10,
          agency_commission: 160000,
          meeting: false,
          next_contact: "15.02.2023",
          focus: false,
        },
        {
          id: 5,
          created: "02.02.2023",
          i1: "онлайн",
          i2: "Директ.Поиск",
          client: "Васильев М.И.",
          service: "Юр. сопровождение",
          object: "Комната",
          market: "Вторинчый",
          segment: "Бизнес",
          address: "ул. Мясницкая, д. 15",
          agent: "Кузнецов М.В.",
          class: "В",
          max_budget: 4200000,
          percent: 5,
          agency_commission: 210000,
          meeting: false,
          next_contact: "25.02.2023",
          focus: false,
        },
        {
          id: 6,
          created: "04.02.2023",
          i1: "онлайн",
          i2: "Директ.Поиск",
          client: "Петров П.В.",
          service: "Продажа",
          object: "Апартаменты",
          market: "Новостройка",
          segment: "Элитный",
          address: "ул. Большая Дмитровка, д. 2",
          agent: "Иванов И.И.",
          class: "Б",
          max_budget: 4500000,
          percent: 10,
          agency_commission: 450000,
          meeting: false,
          next_contact: "28.02.2023",
          focus: false,
        },
        {
          id: 7,
          created: "11.02.2023",
          i1: "офлайн",
          i2: "Звонок",
          client: "Соколов В.А.",
          service: "Выкуп",
          object: "Паркинг",
          market: "Вторинчый",
          segment: "Эконом",
          address: "ул. Новый Арбат, д. 7",
          agent: "Молочков И.А.",
          class: "А",
          max_budget: 3300000,
          percent: 20,
          agency_commission: 660000,
          meeting: false,
          next_contact: "16.02.2023",
          focus: false,
        },
        {
          id: 8,
          created: "19.02.2023",
          i1: "офлайн",
          i2: "Посещение офиса",
          client: "Михайлов Н.И.",
          service: "Продажа",
          object: "Квартира",
          market: "Новостройка",
          segment: "Бизнес",
          address: "ул. Московская, д. 40",
          agent: "Кузнецов М.В.",
          class: "В",
          max_budget: 3800000,
          percent: 5,
          agency_commission: 190000,
          meeting: false,
          next_contact: "27.02.2023",
          focus: false,
        },
        {
          id: 9,
          created: "24.02.2023",
          i1: "онлайн",
          i2: "Директ.Поиск",
          client: "Новиков Д.Е.",
          service: "Покупка",
          object: "Дом",
          market: "Вторинчый",
          segment: "Элитный",
          address: "ул. Проспект Мира, д. 30",
          agent: "Иванов И.И.",
          class: "A",
          max_budget: 2300000,
          percent: 10,
          agency_commission: 230000,
          meeting: false,
          next_contact: "05.03.2023",
          focus: false,
        },
        {
          id: 10,
          created: "02.03.2023",
          i1: "онлайн",
          i2: "Директ.Поиск",
          client: "Федоров А.С.",
          service: "Юр. сопровождение",
          object: "Участок",
          market: "Вторинчый",
          segment: "Эконом",
          address: "ул. Кутузовский проспект, д. 35",
          agent: "Молочков И.А.",
          class: "А",
          max_budget: 2900000,
          percent: 5,
          agency_commission: 145000,
          meeting: false,
          next_contact: "12.03.2023",
          focus: false,
        },
        {
          id: 11,
          created: "05.03.2023",
          i1: "онлайн",
          i2: "Директ.РСЯ",
          client: "Морозов М.А.",
          service: "Продажа",
          object: "Таунхаус",
          market: "Новостройка",
          segment: "Элитный",
          address: "ул. Ленинская Слобода, д. 4",
          agent: "Иванов И.И.",
          class: "Б",
          max_budget: 5700000,
          percent: 20,
          agency_commission: 1140000,
          meeting: false,
          next_contact: "20.03.2023",
          focus: false,
        },
        {
          id: 12,
          created: "08.03.2023",
          i1: "офлайн",
          i2: "Посещение офиса",
          client: "Волков К.М.",
          service: "Альтернатива",
          object: "Комната",
          market: "Вторинчый",
          segment: "Эконом",
          address: "ул. 1-я Тверская-Ямская, д. 12",
          agent: "Петров П.П.",
          class: "В",
          max_budget: 2050000,
          percent: 15,
          agency_commission: 307500,
          meeting: false,
          next_contact: "17.03.2023",
          focus: false,
        },
        {
          id: 13,
          created: "08.03.2023",
          i1: "офлайн",
          i2: "Звонок",
          client: "Алексеев Л.П.",
          service: "Юр. сопровождение",
          object: "Апартаменты",
          market: "Вторинчый",
          segment: "Бизнес",
          address: "ул. Земляной Вал, д. 3",
          agent: "Кузнецов М.В.",
          class: "В",
          max_budget: 3100000,
          percent: 15,
          agency_commission: 465000,
          meeting: false,
          next_contact: "19.03.2023",
          focus: false,
        },
        {
          id: 14,
          created: "09.03.2023",
          i1: "онлайн",
          i2: "Директ.Поиск",
          client: "Лебедев В.Г.",
          service: "Выкуп",
          object: "Паркинг",
          market: "Новостройка",
          segment: "Эконом",
          address: "ул. Никольская, д. 8",
          agent: "Петров П.П.",
          class: "Б",
          max_budget: 3000000,
          percent: 20,
          agency_commission: 600000,
          meeting: false,
          next_contact: "16.03.2023",
          focus: false,
        },
        {
          id: 15,
          created: "11.03.2023",
          i1: "онлайн",
          i2: "Директ.РСЯ",
          client: "Семенов А.А.",
          service: "Альтернатива",
          object: "Квартира",
          market: "Новостройка",
          segment: "Бизнес",
          address: "ул. Большая Юшуньская, д. 18",
          agent: "Иванов И.И.",
          class: "А",
          max_budget: 7800000,
          percent: 5,
          agency_commission: 390000,
          meeting: false,
          next_contact: "28.03.2023",
          focus: false,
        },
        {
          id: 16,
          created: "14.03.2023",
          i1: "онлайн",
          i2: "Директ.РСЯ",
          client: "Егоров Д.С.",
          service: "Юр. сопровождение",
          object: "Дом",
          market: "Вторинчый",
          segment: "Эконом",
          address: "Новая Басманная, д. 17",
          agent: "Кузнецов М.В.",
          class: "В",
          max_budget: 6400000,
          percent: 5,
          agency_commission: 320000,
          meeting: false,
          next_contact: "10.04.2023",
          focus: false,
        },
        {
          id: 17,
          created: "17.03.2023",
          i1: "офлайн",
          i2: "Звонок",
          client: "Павлов М.К.",
          service: "Продажа",
          object: "Участок",
          market: "Новостройка",
          segment: "Элитный",
          address: "ул. Садовническая, д. 37",
          agent: "Петров П.П.",
          class: "A",
          max_budget: 7100000,
          percent: 10,
          agency_commission: 720000,
          meeting: false,
          next_contact: "25.04.2023",
          focus: false,
        },
        {
          id: 18,
          created: "20.03.2023",
          i1: "офлайн",
          i2: "Посещение офиса",
          client: "Козлов А.А.",
          service: "Юр. сопровождение",
          object: "Таунхаус",
          market: "Вторинчый",
          segment: "Бизнес",
          address: "ул. Дмитрия Ульянова, д. 21",
          agent: "Молочков И.А.",
          class: "А",
          max_budget: 9750000,
          percent: 5,
          agency_commission: 487500,
          meeting: false,
          next_contact: "06.04.2023",
          focus: false,
        },
        {
          id: 19,
          created: "23.03.2023",
          i1: "онлайн",
          i2: "Директ.Поиск",
          client: "Степанов И.В.",
          service: "Альтернатива",
          object: "Комната",
          market: "Вторинчый",
          segment: "Эконом",
          address: "ул. Верхняя Красносельская, д. 9",
          agent: "Иванов И.И.",
          class: "Б",
          max_budget: 8560000,
          percent: 20,
          agency_commission: 1712000,
          meeting: false,
          next_contact: "15.04.2023",
          focus: false,
        },
        {
          id: 20,
          created: "26.03.2023",
          i1: "онлайн",
          i2: "Директ.РСЯ",
          client: "Николаев И.П.",
          service: "Юр. сопровождение",
          object: "Апартаменты",
          market: "Новостройка",
          segment: "Элитный",
          address: "ул. Лесная, д. 13",
          agent: "Петров П.П.",
          class: "В",
          max_budget: 6820000,
          percent: 10,
          agency_commission: 682000,
          meeting: false,
          next_contact: "21.04.2023",
          focus: false,
        },
        {
          id: 21,
          created: "05.04.2023",
          i1: "онлайн",
          i2: "Директ.Поиск",
          client: "Орлов П.Д.",
          service: "Продажа",
          object: "Паркинг",
          market: "Вторинчый",
          segment: "Элитный",
          address: "ул. Ленинградская, д. 29",
          agent: "Кузнецов М.В.",
          class: "В",
          max_budget: 5800000,
          percent: 5,
          agency_commission: 290000,
          meeting: false,
          next_contact: "29.04.2023",
          focus: false,
        },
        {
          id: 22,
          created: "07.04.2023",
          i1: "офлайн",
          i2: "Посещение офиса",
          client: "Андреев В.С.",
          service: "Альтернатива",
          object: "Квартира",
          market: "Новостройка",
          segment: "Эконом",
          address: "ул. Ленинская Слобода, д. 4",
          agent: "Иванов И.И.",
          class: "Б",
          max_budget: 6700000,
          percent: 15,
          agency_commission: 1005000,
          meeting: false,
          next_contact: "05.05.2023",
          focus: false,
        },
        {
          id: 23,
          created: "20.04.2023",
          i1: "офлайн",
          i2: "Звонок",
          client: "Макаров А.О.",
          service: "Юр. сопровождение",
          object: "Дом",
          market: "Новостройка",
          segment: "Элитный",
          address: "ул. Большая Сухаревская, д. 33",
          agent: "Молочков И.А.",
          class: "А",
          max_budget: 5500000,
          percent: 10,
          agency_commission: 550000,
          meeting: false,
          next_contact: "15.05.2023",
          focus: false,
        },
        {
          id: 24,
          created: "12.05.2023",
          i1: "онлайн",
          i2: "Директ.РСЯ",
          client: "Никитин М.М.",
          service: "Продажа",
          object: "Участок",
          market: "Новостройка",
          segment: "Эконом",
          address: "ул. Шаболовка, д. 11",
          agent: "Кузнецов М.В.",
          class: "В",
          max_budget: 3700000,
          percent: 15,
          agency_commission: 555000,
          meeting: false,
          next_contact: "22.05.2023",
          focus: false,
        },
        {
          id: 25,
          created: "14.05.2023",
          i1: "онлайн",
          i2: "Директ.Поиск",
          client: "Захаров Г.А.",
          service: "Юр. сопровождение",
          object: "Таунхаус",
          market: "Вторинчый",
          segment: "Элитный",
          address: "ул. Вавилова, д. 23",
          agent: "Молочков И.А.",
          class: "A",
          max_budget: 4800000,
          percent: 20,
          agency_commission: 960000,
          meeting: false,
          next_contact: "27.05.2023",
          focus: false,
        },
        {
          id: 26,
          created: "25.05.2023",
          i1: "онлайн",
          i2: "Посещение офиса",
          client: "Зайцев А.С.",
          service: "Продажа",
          object: "Комната",
          market: "Новостройка",
          segment: "Элитный",
          address: "ул. Пресненская Набережная, д. 41",
          agent: "Кузнецов М.В.",
          class: "А",
          max_budget: 5600000,
          percent: 15,
          agency_commission: 840000,
          meeting: false,
          next_contact: "08.06.2023",
          focus: false,
        },
        {
          id: 27,
          created: "15.06.2023",
          i1: "офлайн",
          i2: "Звонок",
          client: "Соловьев В.П.",
          service: "Выкуп",
          object: "Апартаменты",
          market: "Вторинчый",
          segment: "Эконом",
          address: "ул. Космонавта Волкова, д. 7",
          agent: "Петров П.П.",
          class: "Б",
          max_budget: 3200000,
          percent: 10,
          agency_commission: 320000,
          meeting: false,
          next_contact: "28.06.2023",
          focus: false,
        },
        {
          id: 28,
          created: "16.06.2023",
          i1: "офлайн",
          i2: "Директ.РСЯ",
          client: "Борисов Е.Н.",
          service: "Юр. сопровождение",
          object: "Паркинг",
          market: "Новостройка",
          segment: "Эконом",
          address: "ул. Покровка, д. 38",
          agent: "Иванов И.И.",
          class: "В",
          max_budget: 3150000,
          percent: 5,
          agency_commission: 157500,
          meeting: false,
          next_contact: "29.06.2023",
          focus: false,
        },
        {
          id: 29,
          created: "22.06.2023",
          i1: "онлайн",
          i2: "Директ.Поиск",
          client: "Ковалев Д.И.",
          service: "Продажа",
          object: "Апартаменты",
          market: "Новостройка",
          segment: "Элитный",
          address: "ул. Малая Бронная, д. 14",
          agent: "Петров П.П.",
          class: "В",
          max_budget: 5580000,
          percent: 10,
          agency_commission: 558000,
          meeting: false,
          next_contact: "28.06.2023",
          focus: false,
        },
        {
          id: 30,
          created: "28.06.2023",
          i1: "онлайн",
          i2: "Директ.Поиск",
          client: "Антонов Г.П.",
          service: "Покупка",
          object: "Участок",
          market: "Новостройка",
          segment: "Элитный",
          address: "ул. Воздвиженка, д. 16",
          agent: "Кузнецов М.В.",
          class: "Б",
          max_budget: 6400000,
          percent: 10,
          agency_commission: 640000,
          meeting: false,
          next_contact: "11.07.2023",
          focus: false,
        },
        {
          id: 31,
          created: "29.06.2023",
          i1: "онлайн",
          i2: "Дирек.РСЯ",
          client: "Калашников А.К.",
          service: "Продажа",
          object: "Дом",
          market: "Вторинчый",
          segment: "Бизнес",
          address: "ул. Новослободская, д. 19",
          agent: "Молочков И.А.",
          class: "А",
          max_budget: 9800000,
          percent: 20,
          agency_commission: 1960000,
          meeting: false,
          next_contact: "12.07.2023",
          focus: false,
        },
      ],
    };
  },
  mounted() {
    // let resizerDiv = document.getElementById("resizer-bottom");
    //  let resizeWrap = document.getElementById("resizer-wrap")
    // let tbWrab = document.getElementById("table-wrap")
    // tbWrab.style.height = resizeWrap.offsetHeight + 'px'
    // this.setListeners(resizerDiv);
    // for (let i = 0; i <= 20; i++) {
    //   let data = _.cloneDeep(this.tableData[0]);
    //   this.tableData.push(data);
    // }

    const tableWrap = document.querySelector("#table-wrap");
    const footer = document.querySelector("#bottom");
    const nav = document.querySelector("#nav-container");
    const html = document.querySelector("html");

    const wrapperPane = document.getElementsByClassName("pane-wrapper");

    const pane = document.getElementsByClassName("pane");

    const resizerWrap = document.getElementById("resizer-wrap");

    const tableWrapper = document.getElementById("table-wrap");

    tableWrapper.style.maxHeight = resizerWrap.offsetHeight - 10 + "px";
    //  tableWrapper.style.height = resizerWrap.offsetHeight;

    pane[0].style.width = wrapperPane[0].offsetWidth / 2 + "px";
    pane[1].style.width = wrapperPane[0].offsetWidth / 2 + "px";
    // document.querySelector(".gutter").addEventListener("mousedown", this.resizer);

    tableWrap.style.height =
      html.clientHeight - (footer.clientHeight + nav.clientHeight + 35) + "px";
  },
  methods: {
    resizerVertical(e) {
      const topPane = document.querySelector("#table-wrap");
      const bottomPane = document.querySelector("#bottom");
      const leftPane = document.querySelector("#left");
      const rightPane = document.querySelector("#right");
      const html = document.querySelector("html");
      const body = document.querySelector("body");
      const footer = document.querySelector("#bottom");
      const nav = document.querySelector("#nav-container");

      window.addEventListener("mousemove", mousemove);
      window.addEventListener("mouseup", mouseup);

      let prevY = e.y;
      const topPanelLocal = topPane.getBoundingClientRect();
      const bottomPanelLocal = bottomPane.getBoundingClientRect();
      const leftPanelLocal = leftPane.getBoundingClientRect();
      const rightPanelLocal = rightPane.getBoundingClientRect();

      footer.style.maxHeight =
        html.clientHeight - (nav.clientHeight + 145) + "px";
      leftPane.style.maxHeight =
        html.clientHeight - (nav.clientHeight + 145 + 54) + "px";
      rightPane.style.maxHeight =
        html.clientHeight - (nav.clientHeight + 145 + 54) + "px";

      function mousemove(e) {
        html.style.userSelect = "none";
        body.style.overflow = "hidden";
        //  topPane.style.overflowX = "hidden";

        let newY = prevY - e.y;

        //  if(footer.clientHeight > 70) {
        topPane.style.height = topPanelLocal.height - newY + "px";
        bottomPane.style.height = bottomPanelLocal.height + newY + "px";
        leftPane.style.height = leftPanelLocal.height + newY + "px";
        rightPane.style.height = rightPanelLocal.height + newY + "px";
        // } else {
        //   footer.style.bottom = '-50px'
        // }
      }

      function mouseup() {
        window.removeEventListener("mousemove", mousemove);
        window.removeEventListener("mouseup", mouseup);
        setTimeout(() => {
          html.style.userSelect = "initial";
          body.style.overflow = "initial";
          //   topPane.style.overflow = "scroll";
        }, 500);
      }
    },
    resizer(e) {
      const leftPane = document.querySelector("#left");
      const rightPane = document.querySelector("#right");
      const container = document.getElementById("pane-wrapper");
      container.style.maxWidth = container.clientWidth + "px";

      window.addEventListener("mousemove", mousemove);
      window.addEventListener("mouseup", mouseup);

      let prevX = e.x;
      const leftPanelLocal = leftPane.getBoundingClientRect();
      const rightPanelLocal = rightPane.getBoundingClientRect();

      function mousemove(e) {
        let newX = prevX - e.x;

        leftPane.style.width = leftPanelLocal.width - newX + "px";
        rightPane.style.width = rightPanelLocal.width + newX + "px";
      }

      function mouseup() {
        window.removeEventListener("mousemove", mousemove);
        window.removeEventListener("mouseup", mouseup);
      }
    },
    editColumn(e) {
      e.target.contentEditable = true;
      e.target.focus();
    },
    sendDataOfColumnEsc(e) {
      if (e.type === "keydown" && e.target.contentEditable === "true") {
        e.preventDefault();
        e.target.contentEditable = false;
      }
    },
    sendDataOfColumn(e) {
      if (
        (e.type === "blur" && e.target.contentEditable === "true") ||
        e.keyCode == 27
      ) {
        e.preventDefault();
        e.target.contentEditable = false;
      }
    },
    setListeners(div) {
      var pageY, currentDiv, nextCol, currentDivWidth, nextColWidth;
      div.addEventListener("mousedown", function (e) {
        currentDiv = document.getElementById("tableId");
        nextCol = currentDiv.nextElementSibling;
        pageY = e.pageY;
        currentDivWidth = currentDiv.offsetHeight;

        // if (nextCol) nextColWidth = nextCol.offsetWidth;
      });

      document.addEventListener("mousemove", function (e) {
        if (currentDiv) {
          var diffX = e.pageY - pageY;

          // if (nextCol) nextCol.style.width = nextColWidth - diffX + "px";

          currentDiv.style.height = currentDivWidth + diffX + "px";
        }
      });

      document.addEventListener("mouseup", function (e) {
        currentDiv = undefined;
        nextCol = undefined;
        pageY = undefined;
        nextColWidth = undefined;
        currentDivWidth = undefined;
      });
    },
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;900&display=swap");
html {
  background: #f5f5f5;
}

#app {
  font-family: "Inter", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  background: #f5f5f5;
}

.table-wrap {
  width: 100%;
  overflow: scroll;
  min-height: 110px;
  height: 100%;
  max-height: 400px;
}

.view-textarea {
  border: 0;
  background: #e9ffbf;
}

.view-container {
  height: 35px;
  position: relative;

  &.long {
    padding-top: 7px;
  }
}

.view-box {
  width: 100%;
  height: 100%;
  padding-top: 7px;
  border-radius: 5px;
  border: 1px solid #c7c7c7;
  background: #fff;
  // overflow: scroll;
  padding: 12px 15px;

  &-right {
    background: #e9ffbf;
  }
}

.resizer-wrap {
  position: relative;
}

.resizer-bottom {
  position: absolute;
  top: 0;
  width: 100%;
  height: 7px;
  cursor: ns-resize;
  border-top: 1px solid #aaaaaa;
  border-bottom: 1px solid #aaaaaa;
  box-shadow: inset 0px 1px 0px 0px #fafafa;
  background: #f5f5f5;

  &::after {
    box-sizing: border-box;
    border-top: 1px solid #999;
    border-bottom: 1px solid #999;
    top: 50%;
    left: calc(50% - 30px / 2);
    height: 3px;
    width: 30px;
    margin-top: calc(-3px / 2);
    margin-bottom: calc(-30px / 2);
    position: absolute;
    content: "";
  }
}

.resizer {
  top: 0;
  position: absolute;
  background: #eeeeee;
  width: 100%;
  height: 7px;
  cursor: ns-resize;
  border-top: 1px solid #aaaaaa;
  border-bottom: 1px solid #aaaaaa;
  box-shadow: inset 0px 1px 0px 0px #fafafa;
  background: #f5f5f5;

  &::after {
    box-sizing: border-box;
    border-top: 1px solid #999;
    border-bottom: 1px solid #999;
    top: 50%;
    left: calc(50% - 30px / 2);
    height: 3px;
    width: 30px;
    margin-top: calc(-3px / 2);
    margin-bottom: calc(-30px / 2);
    position: absolute;
    content: "";
  }
}
.resizer-right {
  right: 0;
  position: absolute;
  background: #eeeeee;
  height: 100%;
  width: 7px;
  cursor: ew-resize;
  border-left: 1px solid #aaaaaa;
  border-right: 1px solid #aaaaaa;
  box-shadow: inset 0px 1px 0px 0px #fafafa;
  background: #f5f5f5;

  &::after {
    box-sizing: border-box;
    border-left: 1px solid #999;
    border-right: 1px solid #999;
    top: 50%;
    left: 50%;
    width: 3px;
    height: 30px;
    margin-left: calc(-3px / 2);
    margin-right: calc(-30px / 2);
    margin-top: calc(-30px / 2);
    position: absolute;
    content: "";
  }
}

.pane-top-container {
  margin-top: 7px !important;
}

.pane-wrapper {
  // height: 100px;
  width: 100%;
  // background: #fff;
  background: #f5f5f5;
  border-top: 6px solid #f5f5f5;
  border-bottom: 6px solid #f5f5f5;
  box-shadow: inset 0px 1px 0px 0px #fafafa;
  display: flex;
}

.pane-inner {
  height: 100%;
  overflow: scroll;
  overflow-x: hidden;
}

.pane {
  padding: 12px 15px;
  padding-bottom: 0;
  padding-right: 0;
  min-width: 1px;
  border-radius: 5px;
  border: 1px solid #c7c7c7;
  background: #fff;
  height: 90px;
  //min-height: 1px;
}

.pane.right {
  //margin-left: 7px;
  background: #e9ffbf;
}

.left {
}

.right {
  position: relative;
}

.gutter {
  width: 6px;
  height: 100%;
  background: #f5f5f5;
  position: absolute;
  top: 0;
  left: 0px;
  // border-left: 1px solid #aaaaaa;
  //border-right: 1px solid #aaaaaa;
  border-start-start-radius: 5px;
  border-end-start-radius: 5px;
  cursor: col-resize;

  &::after {
    box-sizing: border-box;
    border-left: 1px solid #999;
    border-right: 1px solid #999;
    top: 50%;
    left: 50%;
    width: 3px;
    height: 30px;
    margin-left: calc(-3px / 2);
    margin-right: calc(-30px / 2);
    margin-top: calc(-30px / 2);
    position: absolute;
    content: "";
  }
}

.footer {
  position: absolute;
  bottom: 0;
  max-height: 574px;
  min-height: 8px;
  // height: 135px
}
</style>
