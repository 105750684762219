<template>
  <input type="text" :value="modelValue" @input="emitValue" />
</template>
<script>
/* eslint-disable */
import _ from "lodash";
// import { debounce } from './debounce.js'
// const debouncedInput = debounce((e) => 
//   emit("update:modelValue", e.target.value),
//   300 // timeout in ms
// );

export default {
  props: {
    modelValue: String,
    modelModifiers: {
      default: () => ({}),
    },
  },
  emits: ["update:modelValue"],
  methods: {
    emitValue(e) {
        
      let value = e.target.value;
      if (this.modelModifiers.capitalize) {
        value = value.charAt(0).toUpperCase() + value.slice(1);
      }
      _.debounce(function() {
        console.log(e.target.value)
        this.$emit("update:modelValue", value);
      }, 200)
    },
  },
  created() {
    
  },
};
</script>
