<template>
  <table
    id="tableId"
    class="table table-striped"
    @click="clickOnTable"
    v-click-outside="leaveOnTable"
    @wheel="wheelOnTable"
  >
    <thead>
      <tr>
        <th
          v-for="(item, index) in headers"
          :key="index"
          @click="thSort(item.slug)"
        >
          <div class="th-content">
            <div class="th-text">
              {{ item.text }}
            </div>
            <img
              src="/assets/img/arrow-up.svg"
              alt=""
              v-if="checkSortDesc(item.slug) === 1"
            />
            <img
              src="/assets/img/arrow-down.svg"
              alt=""
              v-if="checkSortDesc(item.slug) === 2"
            />
          </div>

          <div
            class="columnSelector"
            @click.stop="() => false"
            @mousedown="resizeMouseDown"
          ></div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(item, index) in sortedTableData"
        :key="index"
        :class="{ 'td-focus': item['focus'] }"
        @click="rowClick($event, item)"
        class="tr-table"
      >
        <td
          v-for="(headerItem, headerIndex) in headers"
          :key="headerIndex"
          
          :class="{ 'text-center': headerItem.center, link: headerItem.link }"
          class="td-text-truncute"
          :style="{ ...headerItem.style }"
        >
          <template v-if="headerItem.type === 'select'">
            <div class="select-item" @click="selectContextMenu(index)">
              {{ item[headerItem.slug] }}
            </div>

            <div
              class="td-select-modal"
              v-click-outside="clickOnLeaveSelectModal"
              v-if="
                selectContextMenuModel.length &&
                selectContextMenuModel.includes(index)
              "
            >
              <div
                class="td-select-modal-item"
                v-for="(option, indexSelect) in headerItem.options"
                :key="indexSelect"
                @click="
                  clickOnSelectItemInModal(index, headerItem.slug, option.value)
                "
              >
                {{ option.text }}
              </div>
            </div>
            <!-- <select
              name=""
              id=""
              class="td-select"
              v-model="item[headerItem.slug]"
            >
              <option
                v-for="(option, index) in headerItem.options"
                :value="option.value"
                :key="index"
              >
                {{ option.text }}
              </option>
            </select> -->
          </template>
          <template v-else-if="headerItem.type === 'formula'">
            <!-- {{ parceFormula(item, index, headerIndex, headerItem) }} -->
            <!-- {{ item[headerItem.slug] }} -->

            <template v-if="item['max_budget'] && item['percent']">
              {{ formula(index) }}
              <!-- {{ item['max_budget'] * item['percent'] / 100 }} -->
              {{ item[headerItem.slug] }}
            </template>
            <template v-else>
              <input
                type="text"
                class="td-input"
                :class="{ link: headerItem.link }"
                v-model="item[headerItem.slug]"
              />
            </template>
          </template>
          <template v-else-if="headerItem.type === 'checkbox'">
            <div class="td-checkbox">
              <img
                :src="
                  checkImg.length && checkImg[index]
                    ? checkImg[index]
                    : defaultCheckImg
                "
                alt=""
                @click="clickCheckbox($event, index, headerItem)"
                @contextmenu="checkboxContextMenu($event, index)"
              />
              <div
                class="td-checkbox-modal"
                v-if="
                  checkboxContextMenuModel.length &&
                  checkboxContextMenuModel.includes(index)
                "
              >
                <img
                  src="/assets/img/check-default.svg"
                  class="check-box-img"
                  alt=""
                  @click="clickCheckboxModal(0, index, headerItem)"
                />
                <img
                  src="/assets/img/check-ok.svg"
                  alt=""
                  class="check-box-img"
                  @click="clickCheckboxModal(1, index, headerItem)"
                />
                <img
                  src="/assets/img/check-no.svg"
                  alt=""
                  class="check-box-img"
                  @click="clickCheckboxModal(2, index, headerItem)"
                />
              </div>
            </div>
          </template>
          <template v-else-if="headerItem.type === 'editable-text'">
            <input
              type="text"
              :value="item[headerItem.slug]"
              class="td-input"
              :class="{ link: headerItem.link }"
              @keyup.enter="
                ($event) => {
                  item[headerItem.slug] = $event.target.value;
                  changeInput($event, index, headerItem.slug);
                }
              "
              @change="
                ($event) => {
                  item[headerItem.slug] = $event.target.value;
                  changeInput($event, index, headerItem.slug);
                }
              "
              @input="
                debounce(() => {
                  // item[headerItem.slug] = $event.target.value;
                })
              "
            />
          </template>
          <template v-else>
            {{ item[headerItem.slug] }}
          </template>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
/* eslint-disable */
import CalculatorMixin from "./CalculatorMixin";
import _ from "lodash";
import { isProxy, toRaw } from "vue";
import InputComponent from "./InputComponent.vue";
import { debounce } from "./debounce.js";
import vClickOutside from 'click-outside-vue3'

const debouncedInput = debounce(
  (e) => emit("update:modelValue", e.target.value),
  300 // timeout in ms
);

export default {
  name: "DataTable",
  mixins: [CalculatorMixin],
  components: {
    InputComponent,
  },
  directives: {
      clickOutside: vClickOutside.directive
    },
  props: {
    headers: {
      type: Array,
      default: [],
    },
    tableData: {
      type: Array,
      default: [],
    },
  },
  setup() {
    function createDebounce() {
      let timeout = null;
      return function (fnc, delayMs) {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          fnc();
        }, delayMs || 500);
      };
    }

    return {
      debounce: createDebounce(),
    };
  },
  data() {
    return {
      currentCol: undefined,
      nextCol: undefined,
      pageX: undefined,
      currentColWidth: undefined,
      checkImg: [],
      defaultCheckImg: "/assets/img/check-default.svg",
      focusTable: 0,
      sortBy: [],
      sortDesc: [],
      localTableData: [],
      ckickOnTableVar: true,
      checkboxContextMenuModel: [],
      selectContextMenuModel: [],
      wheelCount: 0,
      throttle: 100, // .5 seconds
      time: -1,
      sort: true,
    };
  },
  computed: {
    sortedTableData() {
      let sortedData = _.orderBy(this.tableData, this.sortBy, this.sortDesc);
      return sortedData;
    },
    indexFocus() {
      return this.sortedTableData.findIndex((element, indexRow) => {
        return element.focus;
      });
    },
  },
  watch: {
    indexFocus: function (val) {
      const table = document.querySelector("#tableId");
      const rows = table.querySelectorAll("tr");
      const tableWrap = document.querySelector("#table-wrap");

      // const firstRow = rows[val];
      // if (firstRow) {
      //   this.scrollToElm(tableWrap, firstRow, 100);
      // }
    },
  },
  mounted() {
    window.addEventListener("mousemove", this.resizeMouseMove);
    window.addEventListener("mouseup", this.resizeMouseUp);
    window.addEventListener("keyup", this.arrowControl);
    window.addEventListener("keydown", this.arrPrevent);

    const table = document.querySelector("#tableId");
    const headers = table.querySelectorAll("th");

    for (let hr of headers) {
      if (hr.offsetWidth < 100) {
        hr.style.width = 100 + "px";
      }
    }
  },
  methods: {
    leaveOnCheckboxModal() {
      this.checkboxContextMenuModel.length = 0;
    },
    changeInput(event, index, header) {
      event.target.blur();
    },
    scrollToElm(container, elm, duration) {
      var pos = this.getRelativePos(elm);
      this.scrollTo(container, pos.top, 2); // duration in seconds
    },

    getRelativePos(elm) {
      var pPos = elm.parentNode.getBoundingClientRect(), // parent pos
        cPos = elm.getBoundingClientRect(), // target pos
        pos = {};

      (pos.top = cPos.top - pPos.top + elm.parentNode.scrollTop),
        (pos.right = cPos.right - pPos.right),
        (pos.bottom = cPos.bottom - pPos.bottom),
        (pos.left = cPos.left - pPos.left);

      return pos;
    },

    scrollTo(element, to, duration, onDone) {
      var start = element.scrollTop,
        change = to - start,
        startTime = performance.now(),
        val,
        now,
        elapsed,
        t;

      function animateScroll() {
        now = performance.now();
        elapsed = (now - startTime) / 1000;
        t = elapsed / duration;

        // element.scrollTop = start + change * this.easeInOutQuad(t);
        element.scrollTop = start + change;
        if (t < 1) window.requestAnimationFrame(animateScroll);
        else onDone && onDone();
      }

      animateScroll();
    },

    easeInOutQuad(t) {
      return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
    },
    clickCheckboxModal(key, indexRow, headerItem) {
      this.checkboxContextMenuModel.length = 0;
      switch (key) {
        case 0:
          this.tableData[indexRow][headerItem.slug] = 0;
          this.checkImg[indexRow] = this.defaultCheckImg;
          return;
        case 1:
          this.tableData[indexRow][headerItem.slug] = 1;
          this.checkImg[indexRow] = "/assets/img/check-ok.svg";
          return;
        case 2:
          this.tableData[indexRow][headerItem.slug] = 2;
          this.checkImg[indexRow] = "/assets/img/check-no.svg";
          return;
        default:
          this.tableData[indexRow][headerItem.slug] = 0;
          this.checkImg[indexRow] = this.defaultCheckImg;
          return;
      }
    },
    checkboxContextMenu(e, index) {
      e.preventDefault();

      this.checkboxContextMenuModel.push(index);
    },
    clickOnSelectItemInModal(indexRow, headerSlug, value) {
      this.selectContextMenuModel.length = 0;
      this.tableData[indexRow][headerSlug] = value;
    },
    clickOnLeaveSelectModal() {
      this.selectContextMenuModel.length = 0;
    },
    selectContextMenu(index) {
      this.selectContextMenuModel.length = 0;
      this.selectContextMenuModel.push(index);
    },
    clickOnTable() {
      this.ckickOnTableVar = true;
    },
    leaveOnTable() {
      this.ckickOnTableVar = false;
    },
    setFocusOnTable(index) {
      this.sortedTableData.map((item) => (item.focus = false));
      this.sortedTableData[index]["focus"] = true;
    },
    checkSortDesc(item) {
      if (this.sortBy.includes(item)) {
        let index = this.sortBy.indexOf(item);

        if (index != -1) {
          if (this.sortDesc[index] === "asc") {
            return 1;
          }
          if (this.sortDesc[index] === "desc") {
            return 2;
          }
        }
      }
      return 0;
    },
    thSort(item) {
      if (this.sortBy.includes(item)) {
        let index = this.sortBy.indexOf(item);

        if (index != -1) {
          if (this.sortDesc[index] === "asc") {
            this.sortDesc[index] = "desc";
          } else {
            this.sortBy.splice(index, 1);
            this.sortDesc.splice(index, 1);
          }
        }
      } else {
        this.sortBy.push(item);
        this.sortDesc.push("asc");
      }

      this.setFocusOnTable(0);
    },
    wheelOnTable(e) {
      //e.preventDefault();
      // e.stopPropagation();
      const table = document.querySelector("#tableId");
      const tableWrap = document.querySelector("#table-wrap");
      const html = document.querySelector("html");
      html.style.overflow = "hidden";
      // tableWrap.style.overflowY = "hidden";

      let now = Date.now(); // Current time, in milliseconds
      if (this.time !== -1 && now - this.time < this.throttle) return; // Get out, we haven't waited long enough
      this.time = now;

      let delta = e.deltaY; // || e.detail || e.wheelDelta;

      // получаем все строки таблицы
      const rows = table.querySelectorAll("tr");

      let elFocus = this.indexFocus;

      //  setTimeout(function () {
      if (delta > 3) {
        if (elFocus === this.tableData.length - 1) {
          this.setFocusOnTable(this.tableData.length - 1);
          // this.focusTable = this.tableData.length - 1;
        } else {
          this.setFocusOnTable(elFocus + 1);
          //  this.focusTable = this.focusTable + 1;
        }
      }
      if (delta < -3) {
        if (elFocus === 0) {
          //  this.focusTable = 0;
          this.setFocusOnTable(0);
        } else {
          this.setFocusOnTable(elFocus - 1);
          // this.focusTable = this.focusTable - 1;
        }

        const firstRow = rows[this.indexFocus];
        if (firstRow) {
          this.scrollToElm(tableWrap, firstRow, 100);
        }
      }
    },
    rowClick(e, itemRow) {
      this.clickOnTable();
      this.sortedTableData.map((item) => (item.focus = false));
      let findItem = this.sortedTableData.find(
        (item) => item.id === itemRow.id
      );
      findItem.focus = true;
      // this.setFocusOnTable(indexRow);
    },
    arrPrevent(e) {
      if (this.ckickOnTableVar) {
        if (e.which === 38 || e.which === 40) {
          e.preventDefault();
        }
      }
    },
    arrowControl(e) {
      const table = document.querySelector("#tableId");
      const tableWrap = document.querySelector("#table-wrap");

      // получаем все строки таблицы
      const rows = table.querySelectorAll("tr");
      let isHover = table.matches(":hover");

      let elFocus = this.indexFocus;

      if (this.ckickOnTableVar) {
        if (e.which === 38 || e.which === 40) {
          //  e.target.focus()

          e.preventDefault();
          if (e.which === 38 && elFocus === 0) {
            elFocus = 0;
            return;
          }

          if (e.which === 40 && elFocus === this.tableData.length - 1) {
            this.setFocusOnTable(this.tableData.length - 1);
            //  this.focusTable = this.tableData.length - 1;
            return;
          }

          if (e.which === 38) {
            this.setFocusOnTable(elFocus - 1);
            // this.focusTable = this.focusTable - 1;
          }

          if (e.which === 40) {
            this.setFocusOnTable(elFocus + 1);
            // this.focusTable = this.focusTable + 1;
          }

          const firstRow = rows[this.indexFocus];
          if (firstRow) {
            this.scrollToElm(tableWrap, firstRow, 100);
          }
        }
      }
    },
    clickCheckbox(e, indexRow, headerItem) {
      switch (Number(this.tableData[indexRow][headerItem.slug])) {
        case 0:
          this.tableData[indexRow][headerItem.slug] = 1;
          this.checkImg[indexRow] = "/assets/img/check-ok.svg";
          return;
        case 1:
          this.tableData[indexRow][headerItem.slug] = 2;
          this.checkImg[indexRow] = "/assets/img/check-no.svg";
          return;
        case 2:
          this.tableData[indexRow][headerItem.slug] = 0;
          this.checkImg[indexRow] = this.defaultCheckImg;
          return;
        default:
          this.tableData[indexRow][headerItem.slug] = 0;
          this.checkImg[indexRow] = this.defaultCheckImg;
          return;
      }
    },
    formula(index) {
      this.tableData[index]["agency_commission"] =
        (this.tableData[index]["max_budget"] *
          this.tableData[index]["percent"]) /
        100;
    },
    parceFormula(item, indexRow, headerIndex, headerItem) {
      let formula = this.headers[headerIndex].formula;

      Object.keys(this.tableData[indexRow]).map((itemMap) => {
        if (this.headers[headerIndex].formula.includes(itemMap)) {
          formula = formula.replace(
            new RegExp(`${itemMap}`, `gi`),
            this.tableData[indexRow][itemMap]
          );
        }
      });

      let result = this.parseBrackets(formula);

      if (!isNaN(Number(result))) {
        this.tableData[indexRow][headerItem.slug] = result;
        // return result;
      } else {
        this.tableData[indexRow][headerItem.slug] = "";
        // return "";
      }
    },
    onInput(e, index, header) {
      //  this.tableData[index][header] = e.target.innerText;
    },
    sendDataOfColumn(e, index, header, item) {
      const table = document.querySelector("#tableId");
      const rows = table.querySelectorAll("tr");
      const tableWrap = document.querySelector("#table-wrap");

      if (
        (e.type === "blur" && e.target.contentEditable === "true") ||
        e.which === 13
      ) {
        //  e.preventDefault();
      }
    },
    resizeMouseDown(e) {
      this.currentCol = e.target.parentElement;
      this.nextCol = this.currentCol.nextElementSibling;
      this.pageX = e.pageX;
      this.currentColWidth = this.currentCol.offsetWidth;
    },
    resizeMouseUp(e) {
      this.currentCol = undefined;
      this.nextCol = undefined;
      this.pageX = undefined;
      this.currentColWidth = undefined;
    },
    resizeMouseMove(e) {
      if (this.currentCol) {
        let diffX = e.pageX - this.pageX;

        // if (this.nextCol) this.nextCol.style.width = this.nextColWidth - diffX + "px";

        this.currentCol.style.width = this.currentColWidth + diffX + "px";

        this.currentCol.style.width = this.currentColWidth + diffX + "px";
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
* {
  box-sizing: border-box;
}
table {
  border-collapse: collapse;
  overflow: scroll;
  width: max-content;
  border: 0 !important;
  /* min-width: 100vw;
  width: auto; */
}

td {
  //min-width: 100px;
}

td,
th {
  padding: 0.2rem 0.2rem !important;
  text-align: left;
  border: 0 !important;
}

table,
th,
td {
  border: 1px solid #000;
}

th {
  // position: relative;
  background: #568300 !important;
  color: #fff !important;
  border-right: 1px solid #fff !important;
  position: -webkit-sticky;
  position: sticky;
  top: 0px; // при нуле в chrome остаётся странный зазор
  z-index: 2;
}

.td-input {
  background: transparent;
  border: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
}

.th-content {
  display: flex;
}

.th-text {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.link {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.link:hover {
  cursor: pointer;
}

.td-focus {
  background: #d3ff80;
  --bs-table-bg: #d3ff80;
}

.input-editable {
  background: transparent;
  border: 0;
}

.check-box-img {
  cursor: pointer;
  margin-top: 3px;
  margin-bottom: 3px;
}

.tr-table {
  position: relative;
}

.td-text-truncute {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 1px;
}

.td-select {
  border: 0;
  background: transparent;
}

.td-checkbox {
  width: min-content;
  margin: auto;
}

.select-item {
  cursor: pointer;
}

.td-select-modal-item {
  margin: 0 10px;
  cursor: pointer;

  &:hover {
    color: #568300;
  }
}

.td-select-modal {
  position: absolute;
  top: 25px;
  display: flex;
  flex-direction: column;
  z-index: 1;
  background: #fff;
  padding: 8px 10px;
  border-end-end-radius: 5px;
  border-end-start-radius: 5px;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
}

.td-checkbox-modal {
  position: absolute;
  top: 10px;
  display: flex;
  flex-direction: column;
  z-index: 1;
  background: #fff;
  padding: 2px;
  border-end-end-radius: 3px;
  border-end-start-radius: 3px;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
}

.columnSelector {
  height: 100%;
  top: 0px;
  right: 0px;
  width: 5px;
  position: absolute;
  cursor: col-resize;
  user-select: none;
}

@supports (-moz-appearance: none) {
  .columnSelector {
    border-right: 1px solid #fff;
  }
}

.columnSelector:hover {
  background: 2px solid #0000ff;
}
</style>
