import {
    createApp
} from 'vue'
import App from './App.vue'
import VueExcelEditor from "vue3-excel-editor";
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import "./polyfills"
//import vClickOutside from 'v-click-outside'

const clickOutside = {
    beforeMount: (el, binding) => {
      el.clickOutsideEvent = event => {
        // here I check that click was outside the el and his children
        if (!(el == event.target || el.contains(event.target))) {
          // and if it did, call method provided in attribute value
          binding.value();
        }
      };
      document.addEventListener("click", el.clickOutsideEvent);
    },
    unmounted: el => {
      document.removeEventListener("click", el.clickOutsideEvent);
    },
  };

const app = createApp(App)
app.use(VueExcelEditor)
//app.use(vClickOutside)
//app.directive('click-outside', clickOutside);

app.mount('#app')